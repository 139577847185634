* {
	box-sizing: border-box;
	background-color: rgb(29, 29, 56);
	color: rgb(202, 197, 187);
	font-family: Arial, Helvetica, sans-serif;
}

.layout {
	margin: 0 10vw;
}

h1 {
	font-size: 50px;
}

div {
	font-size: 25px;
}

pre {
	font-family: 'Courier New', Courier, monospace;
	font-weight: 300;
}